import { addHours } from "date-fns";

import { formatDate } from "../lib/date";

import BaseModel from "./base-model";
import Spot from "./Spot";

export const DELIVERY_STATUS_0 = "DELIVERY_STATUS_RECEIPT";
export const DELIVERY_STATUS_1 = "DELIVERY_STATUS_PICKUP_SCHEDULED";
export const DELIVERY_STATUS_2 = "DELIVERY_STATUS_PICKUP_COMPLETED";
export const DELIVERY_STATUS_3 = "DELIVERY_STATUS_WAREHOUSED"; // 입고 | 배송대기
export const DELIVERY_STATUS_4 = "DELIVERY_STATUS_DELIVERY_STARTED"; // 배송출발
export const DELIVERY_STATUS_5 = "DELIVERY_STATUS_DELIVERED";
export const DELIVERY_STATUS_6 = "DELIVERY_STATUS_DELIVERY_SENT_BACK";
export const DELIVERY_STATUS_7 = "DELIVERY_STATUS_DELIVERY_LOST";
export const DELIVERY_STATUS_8 = "DELIVERY_RIDER_ALLOCATED"; // 배송대기
export const DELIVERY_STATUS_9 = "ADDRESS_NOT_SUPPORTED";
export const DELIVERY_STATUS_10 = "ACCIDENT";
export const DELIVERY_STATUS_11 = "CANCELED";
export const DELIVERY_STATUS_12 = "DELIVERY_POSTPONED"; // 배송연기

// export const STATUS_PICKUP = "PICKUP";
// export const STATUS_PICKUP_COMPLETED = "PICKUP_COMPLETED"; // 수거 완료
// export const STATUS_DELIVERY_WAITING = "DELIVERY_WAITING"; // 배송배차
// export const STATUS_DELIVERY_POSTPONED = "DELIVERY_POSTPONED"; // 배송연기
// export const STATUS_DELIVERY_STARTED = "DELIVERY_STARTED"; // 배송출발
// export const STATUS_DELIVERY_ALLOCATED = "DELIVERY_ALLOCATED";
// export const STATUS_COMPLETED = "DELIVERED";
// export const STATUS_SENT_BACK = "SENT_BACK";
// export const STATUS_LOST = "LOST";
// export const STATUS_ADDRESS_NOT_SUPPORTED = "ADDRESS_NOT_SUPPORTED";

export const STATUS_PICKUP_FOR_COUNT = "pickup-scheduled";
export const STATUS_DELIVERY_ALLOCATED_FOR_COUNT = "delivery-allocated";
export const STATUS_DELIVERY_WAITING_FOR_COUNT =
  "delivery-allocated-before-start";
export const STATUS_DELIVERY_POSTPONED_FOR_COUNT = "delivery-postponed";
export const STATUS_DELIVERY_STARTED_FOR_COUNT = "delivery-started";
export const STATUS_DELIVERED_FOR_COUNT = "delivered";

export const DELIVERY_TYPE_NORMAL = "1";
export const DELIVERY_TYPE_PICKUP = "2";
export const DELIVERY_TYPE_RETURN = "3";
export const DELIVERY_TYPE_PICKUP_RETURN = "2,3";

export const ADDRESS_API_TYPE_ONE = "SOOJIONE";
export const ADDRESS_API_TYPE_KAKAO = "KAKAO";

export const SPOT_CODE_FRESHCODE = "P0020"; // 프레시코드 spot code

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

function getDistance(lat1, lng1, lat2, lng2) {
  let R = 6371; // Radius of the earth in km
  let dLat = deg2rad(lat2 - lat1); // deg2rad below
  let dLon = deg2rad(lng2 - lng1);
  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = R * c; // Distance in km

  return Math.round(d * 1000);
}

function getPointToArray(point) {
  if (point && point.indexOf("POINT") > -1) {
    let pointArray = point.substring(6, point.length - 1).split(" ");

    return pointArray;
  } else {
    return [0, 0];
  }
}

export const parseDeliveryStatus = (status) => {
  switch (Number(status)) {
    case 0:
      return DELIVERY_STATUS_0;
    case 1:
      return DELIVERY_STATUS_1;
    case 2:
      return DELIVERY_STATUS_2;
    case 3:
      return DELIVERY_STATUS_3;
    case 4:
      return DELIVERY_STATUS_4;
    case 5:
      return DELIVERY_STATUS_5;
    case 6:
      return DELIVERY_STATUS_6;
    case 7:
      return DELIVERY_STATUS_7;
    case 8:
      return DELIVERY_STATUS_8;
    case 9:
      return DELIVERY_STATUS_9;
    case 10:
      return DELIVERY_STATUS_10;
    case 11:
      return DELIVERY_STATUS_11;
    case 12:
      return DELIVERY_STATUS_12;
    default:
      return "알수없음";
  }
};

export default class Delivery extends BaseModel {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);

    this.receiverDong = props.receiverDongObj?.name || props.receiverDong;
    this.senderDong = props.senderDongObj?.name || props.senderDong;
    this.status = parseDeliveryStatus(props.status);
    this.statusNubmer = props.status;
    this.spot = props.spot ? new Spot(props.spot) : null;
  }

  get addressApiType() {
    return this.etc4Obj ? this.etc4Obj.addressApiType : "";
  }

  get compareReceiverLocation() {
    return {
      longitude: getPointToArray(this.receiverPoint)[0],
      latitude: getPointToArray(this.receiverPoint)[1],
    };
  }

  get customerAddress() {
    return this.etc4Obj ? this.etc4Obj.customerAddress : null;
  }
  get customerAddressDetail() {
    return this.etc4Obj ? this.etc4Obj.customerAddressDetail : "";
  }

  get customerAddressEng() {
    return this.etc4Obj ? this.etc4Obj.customerAddressEng : "";
  }

  get customerAddressEngGpt() {
    return this.etc4Obj ? this.etc4Obj.customerAddressEngGpt : "";
  }

  get deliveryPlace() {
    return this.etc4Obj ? this.etc4Obj.deliveryPlace : "";
  }

  get deliveryPlacedTime() {
    return this.etc4Obj ? this.etc4Obj.deliveryPlacedTime : "";
  }

  get displayAddress() {
    if (this.unrefinedAddress) {
      return this.unrefinedAddress;
    } else if (this.customerAddress) {
      return `${this.customerAddress}${
        this.customerAddressDetail &&
        this.customerAddressDetail !== this.customerAddress
          ? `, ${this.customerAddressDetail}`
          : ""
      }`;
    } else {
      return `${this.receiverAddress || this.receiverAddressRoad || ""}${
        this.receiverAddressDetail ? `, ${this.receiverAddressDetail}` : ""
      }`;
    }
  }

  get distanceRefineToLimitedRefined() {
    if (this.soojioneLimitedCustomized?.point && this.receiverPoint) {
      return getDistance(
        Number(this.compareReceiverLocation.latitude),
        Number(this.compareReceiverLocation.longitude),
        Number(this.soojioneLimitedCustomizedLngLat.latitude),
        Number(this.soojioneLimitedCustomizedLngLat.longitude),
      );
    } else {
      return null;
    }
  }

  get etc4Obj() {
    try {
      return this.etc4 && JSON.parse(this.etc4);
    } catch (e) {
      return {};
    }
  }

  get freshBarcode() {
    return this.etc4Obj ? this.etc4Obj.freshBarcode : "";
  }

  get frontdoorPassword() {
    if (this.frontdoorPasswordByClient) {
      return `${this.frontdoorPasswordByClient} ${
        this.frontdoorPasswordByCorp ? `/ ${this.frontdoorPasswordByCorp}` : ""
      }`;
    } else {
      return this.frontdoorPasswordByCorp || null;
    }
  }

  get frontdoorPasswordByClient() {
    return this.etc4Obj ? this.etc4Obj.frontdoorPasswordByClient : "";
  }

  get frontdoorPasswordByCorp() {
    return this.etc4Obj ? this.etc4Obj.frontdoorPasswordByCorp : "";
  }

  get isConvenienceBarcode() {
    return (
      this.status === DELIVERY_STATUS_4 &&
      this.spot?.code === SPOT_CODE_FRESHCODE &&
      this.freshBarcode
    );
  }

  get isHighPrice() {
    return (
      this.statusDelivery &&
      (this.productPrice >= 200000 || this.spotLuxuryAvailable)
    );
  }

  get isLimitedHours() {
    return (
      (this.status === DELIVERY_STATUS_3 ||
        this.status === DELIVERY_STATUS_4 ||
        this.status === DELIVERY_STATUS_8 ||
        this.status === DELIVERY_STATUS_12) &&
      this.limitedDeliveryHours > 0
    );
  }

  get isrequiredDelayMessage() {
    return (
      (this.status === DELIVERY_STATUS_12 ||
        this.status === DELIVERY_STATUS_4) &&
      Number(formatDate(new Date(), "HH")) > 21 &&
      (!this.delayedDeliveries || this.delayedDeliveries.length === 0)
    );
  }

  get limitedDeliveryAt() {
    return this.pickupDateCompleted && this.limitedDeliveryHours > 0
      ? addHours(new Date(this.pickupDateCompleted), this.limitedDeliveryHours)
      : null;
  }

  get limitedDeliveryHours() {
    return this.spot
      ? this.spot.limitedDeliveryHours
      : this.etc4Obj
      ? this.etc4Obj.limitedDeliveryHours
      : null;
  }

  get memo() {
    if (this.deliveryPlace) {
      return `(${formatDate(this.deliveryPlacedTime, "HH:mm")})${
        this.deliveryPlace
      } / ${this.memoFromCustomer || "없음"}`;
    } else {
      return this.memoFromCustomer;
    }
  }

  get mustSignature() {
    return this.etc4Obj ? this.etc4Obj.mustSignature : false;
  }

  get nonDoobalType() {
    return this.etc4Obj?.nonDoobalType;
  }

  get receiverAddressObj() {
    return this.etc4Obj ? this.etc4Obj.receiverAddressObj : null;
  }

  get receiverAddressRoadWithoutSigungu() {
    if (this.receiverAddressRoad && this.receiverSigungu) {
      return this.receiverAddressRoad.split(this.receiverSigungu)[
        this.receiverAddressRoad.split(this.receiverSigungu).length - 1
      ];
    } else if (this.receiverAddressRoad) {
      return this.receiverAddressRoad.split(" ").slice(2).join(" ");
    } else {
      return this.receiverAddressRoad;
    }
  }

  get receiverAddressWithoutSigungu() {
    if (this.receiverAddress && this.receiverSigungu) {
      return this.receiverAddress.split(this.receiverSigungu)[
        this.receiverAddress.split(this.receiverSigungu).length - 1
      ];
    } else if (this.receiverAddress) {
      return this.receiverAddress.split(" ").slice(2).join(" ");
    } else {
      return this.receiverAddress;
    }
  }

  get receiverBuilding() {
    if (this.etc4Obj.receiverBuilding) {
      return this.etc4Obj.receiverBuilding;
    } else if (this.receiverAddressObj && this.receiverAddressObj.building) {
      return this.receiverAddressObj.building;
    } else {
      return null;
    }
  }

  get receiverBuildingDongNumber() {
    if (this.receiverAddressObj && this.receiverAddressObj.buildingDongNumber) {
      return `${this.receiverAddressObj.buildingDongNumber}동`;
    } else {
      return null;
    }
  }

  get receiverSigungu() {
    if (this.receiverAddress && this.receiverDong) {
      return this.receiverAddress.split(this.receiverDong)[0];
    } else {
      return "";
    }
  }

  get recentDelayedDelivery() {
    if (this.delayedDeliveries?.length > 0) {
      return this.delayedDeliveries[this.delayedDeliveries.length - 1];
    } else {
      return null;
    }
  }

  get renderAddressApiType() {
    if (this.addressApiType === "TMAP") {
      return "서브1 정제:기본정제실패";
    } else if (this.addressApiType === "KAKAO") {
      return "서브2 정제:기본정제실패";
    } else if (this.addressApiType === "SOOJIONE") {
      if (this.soojioneLimitedCustomized) {
        return "입력 주소값 일치 정제";
      } else {
        return "건물명 매칭 조정정제";
      }
    } else {
      return "";
    }
  }

  get sendedCompletedSms() {
    return this.etc4Obj ? this.etc4Obj.sendedCompletedSms : false;
  }

  get senderAddressObj() {
    return this.etc4Obj ? this.etc4Obj.senderAddressObj : null;
  }

  get senderAddressRoadWithoutSigungu() {
    if (this.senderAddressRoad && this.senderSigungu) {
      return this.senderAddressRoad.split(this.senderSigungu)[
        this.senderAddressRoad.split(this.senderSigungu).length - 1
      ];
    } else if (this.senderAddressRoad) {
      return this.senderAddressRoad.split(" ").slice(2).join(" ");
    } else {
      return this.senderAddressRoad;
    }
  }

  get senderBuilding() {
    if (this.etc4Obj?.senderBuilding) {
      return this.etc4Obj.senderBuilding;
    } else if (this.senderAddressObj && this.senderAddressObj.building) {
      return this.senderAddressObj.building;
    } else {
      return null;
    }
  }

  get senderBuildingDongNumber() {
    if (this.senderAddressObj && this.senderAddressObj.buildingDongNumber) {
      return `${this.senderAddressObj.buildingDongNumber}동`;
    } else {
      return null;
    }
  }

  get senderSigungu() {
    if (this.senderAddress && this.senderDong) {
      return this.senderAddress.split(this.senderDong)[0];
    } else {
      return "";
    }
  }

  get soojioneLimitedCustomized() {
    return this.etc4Obj ? this.etc4Obj.soojioneLimitedCustomized : null;
  }

  get soojioneLimitedCustomizedLngLat() {
    return {
      longitude: getPointToArray(this.soojioneLimitedCustomized?.point)[0],
      latitude: getPointToArray(this.soojioneLimitedCustomized?.point)[1],
    };
  }

  get spotEtcObj() {
    try {
      return this.spot?.etc && JSON.parse(this.spot?.etc);
    } catch (e) {
      return {};
    }
  }

  get spotLuxuryAvailable() {
    return !!this.spotEtcObj?.luxuryAvailable;
  }

  get spotKurlyDrink() {
    return !!this.spotEtcObj?.kurlyDrink;
  }

  get spotName() {
    return this.spot?.name || this.senderName;
  }

  get statusAddressNotSupported() {
    if (this.status === DELIVERY_STATUS_9) {
      if (this.deliveryRider?.id === this.newRider?.rider.id) {
        return DELIVERY_STATUS_4;
      } else if (this.pickupRider?.id === this.newRider?.rider.id) {
        return DELIVERY_STATUS_1;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  get statusDelivery() {
    return (
      this.status === DELIVERY_STATUS_3 ||
      this.status === DELIVERY_STATUS_4 ||
      this.status === DELIVERY_STATUS_8 ||
      this.status === DELIVERY_STATUS_12 ||
      this.statusAddressNotSupported === DELIVERY_STATUS_4
    );
  }

  get statusDeliveryStart() {
    return (
      this.status === DELIVERY_STATUS_4 ||
      this.statusAddressNotSupported === DELIVERY_STATUS_4
    );
  }

  get statusDeliveryCompleted() {
    return this.status === DELIVERY_STATUS_5;
  }

  get statusDeliverySentBack() {
    return this.status === DELIVERY_STATUS_6;
  }

  get statusDeliveryLost() {
    return this.status === DELIVERY_STATUS_7;
  }

  get statusPickup() {
    return (
      this.status === DELIVERY_STATUS_1 ||
      this.statusAddressNotSupported === DELIVERY_STATUS_1
    );
  }

  get statusString() {
    if (this.addressNotSupported) {
      switch (this.status) {
        case DELIVERY_STATUS_5:
          return "배송완료(배송불가)";
        case DELIVERY_STATUS_6:
          return "반송완료(배송불가)";
        case DELIVERY_STATUS_7:
          return "분실완료(배송불가)";
        default:
          return "배송불가";
      }
    } else {
      switch (this.status) {
        case DELIVERY_STATUS_1:
          return "미수거";
        case DELIVERY_STATUS_2:
          return "수거완료";
        case DELIVERY_STATUS_3:
        case DELIVERY_STATUS_8:
          return "대기";
        case DELIVERY_STATUS_12:
          return "연기";
        case DELIVERY_STATUS_4:
          return "배송";
        case DELIVERY_STATUS_5:
          return "배송완료";
        case DELIVERY_STATUS_6:
          return "반송완료";
        case DELIVERY_STATUS_7:
          return "분실완료";
        case DELIVERY_STATUS_9:
          return "배송불가";
        default:
          return "";
      }
    }
  }

  get statusWaiting() {
    return (
      this.status === DELIVERY_STATUS_3 || this.status === DELIVERY_STATUS_8
    );
  }

  get typeNormal() {
    return this.type === DELIVERY_TYPE_NORMAL;
  }

  get typePickupReturn() {
    return (
      this.type === DELIVERY_TYPE_PICKUP || this.type === DELIVERY_TYPE_RETURN
    );
  }
}
