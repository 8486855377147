import BaseModel from "./base-model";

export default class User extends BaseModel {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  get userEtcObj() {
    try {
      return this.etc && JSON.parse(this.etc);
    } catch (e) {
      return {};
    }
  }

  get signatureImages() {
    return this.userEtcObj?.signatureImages;
  }

  // 위탁계약서 서명
  get consignmentAgreement() {
    return this.signatureImages?.consignmentAgreement;
  }

  // 개인정보보안서약서 서명
  get personalInformationSecurityPledge() {
    return this.signatureImages?.personalInformationSecurityPledge;
  }

  // 개인정보제공 동의서 서명
  get personalInformationConsentForm() {
    return this.signatureImages?.personalInformationConsentForm;
  }

  // 개인정보보호교육 수강 일시
  get enrolledPrivacyCourseAt() {
    return this.userEtcObj?.enrolledPrivacyCourseAt;
  }
}
