/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback } from "react";
import { atom, useRecoilState } from "recoil";

import api from "../Api";

import User from "../../models/User";

export const PUSH_NOTIFICATION = {
  CS_CREATED: "CS_CREATED", // CS등록 푸시메시지: 온/오프 설정 불가
  DELIVERY_ALLOCATED: "DELIVERY_ALLOCATED", // 배송배차 푸시메시지 : 온/오프 설정 가능
  NOT_DELIVERED_REASON_REGISTERED: "NOT_DELIVERED_REASON_REGISTERED", // 미배송사유등록 푸시메시지: 온/오프 설정 불가
  PICKUP_ALLOCATED: "PICKUP_ALLOCATED", // 수거지정 푸시메시지: 온/오프 설정 가능
  REALLOCATED_DELIVERY_START_TO_NEW_RIDER:
    "REALLOCATED_DELIVERY_START_TO_NEW_RIDER", // 재배차 등록(배송건 제외) 푸시메시지: 온/오프 설정 가능
  REALLOCATED_DELIVERY_START_TO_OLD_RIDER:
    "REALLOCATED_DELIVERY_START_TO_OLD_RIDER", // 재배차 등록(배송건 등록) 푸시메시지: 온/오프 설정 가능
};

const pushNotificationAtom = atom({
  key: "push-notification",
  default: {
    [PUSH_NOTIFICATION.DELIVERY_ALLOCATED]: true,
    [PUSH_NOTIFICATION.PICKUP_ALLOCATED]: true,
    [PUSH_NOTIFICATION.REALLOCATED_DELIVERY_START_TO_NEW_RIDER]: true,
    [PUSH_NOTIFICATION.REALLOCATED_DELIVERY_START_TO_OLD_RIDER]: true,
  },
});

const userAtom = atom({
  key: "user",
  default: {},
});

export const useAuthStore = () => {
  const [pushNotification, setPushNotification] =
    useRecoilState(pushNotificationAtom);
  const [user, setUser] = useRecoilState(userAtom);

  const fetchMe = async () => {
    const _user = await api.get("/me");

    setUser(new User(_user));
  };

  const fetchPushNotification = useCallback(async () => {
    const response = await api.get("/me/push-toggles");

    setPushNotification(response);
  }, []);

  const updateContractSignature = useCallback(async (data) => {
    return await api.put(`/me/signature-images`, data);
  }, []);

  const updateEnrollPrivacyCours = useCallback(async (data) => {
    return await api.put(`/me/enrolled-privacy-course`, data);
  }, []);

  const updatePassword = useCallback(async (data) => {
    return await api.put(`/me/password`, data);
  }, []);

  const updatePushNotification = useCallback(
    async (data) => {
      const response = await api.put(`/me/push-toggles`, data);

      setPushNotification({
        ...pushNotification,
        [data.type]: data.state,
      });

      return response;
    },
    [pushNotification],
  );

  const state = {
    pushNotification,
    user,
  };

  return {
    state,
    fetchMe,
    fetchPushNotification,
    updateContractSignature,
    updateEnrollPrivacyCours,
    updatePassword,
    updatePushNotification,
  };
};
