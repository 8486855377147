export const CORPS = [
  {
    displayName: "한진(아마존)",
    senderName: "한진(배송원직접추가)",
    corpUser: "hanjinapi",
    spotCode: "10480",
  },
  {
    displayName: "올리브영 서울",
    senderName: "올리브영 서울",
    corpUser: "cjoyapi",
    spotCode: "10334",
  },
  {
    displayName: "올리브영 군포",
    senderName: "올리브영 군포",
    corpUser: "cjoyapi",
    spotCode: "10499",
  },
  {
    displayName: "올리브영 성남",
    senderName: "올리브영 성남",
    corpUser: "cjoyapi",
    spotCode: "10520",
  },
  {
    displayName: "올리브영 부천",
    senderName: "올리브영 부천",
    corpUser: "cjoyapi",
    spotCode: "10540",
  },
  {
    displayName: "올리브영 해운대",
    senderName: "올리브영 해운대",
    corpUser: "cjoyapi",
    spotCode: "10549",
  },
  {
    displayName: "직접입력",
    senderName: "",
    corpUser: "직접입력",
    spotCode: "직접입력",
  },
];
