/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";

import DeliveryDelayModal from "../modals/DeliveryDelayModal";
import DeliveryLostModal from "../modals/DeliveryLostModal";
import DeliverySentBackModal from "../modals/DeliverySentBackModal";

import {
  useBaseAddressesStore,
  useDeliveriesStore,
  useLoadingStore,
  useModalStore,
} from "../stores/hooks";

import { formatDate } from "../lib/date";

const InfoRow = ({ children }) => {
  return <dl className="flex justify-start items-start">{children}</dl>;
};

const InfoLabel = ({ children }) => {
  return <dt className="w-80 text-[#828182] text-16">{children}</dt>;
};

const InfoValue = ({ children }) => {
  return <dd className="flex-1 text-16">{children}</dd>;
};

const ButtonsWrap = ({ children }) => {
  return (
    <div className="flex justify-start items-center gap-5">{children}</div>
  );
};

const ButtonSquare = ({ onClick, bgColor, children }) => {
  return (
    <button
      type="button"
      className={`${bgColor || "bg-orange"} text-white py-4 px-12 flex-1`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

const ButtonRound = ({ onClick, type, minWidth, children }) => {
  let classNames = "bg-orange text-white";
  if (type === "ORANGE") {
    classNames = "bg-orange text-white";
  }

  // min-w-[80px]

  return (
    <button
      type="button"
      className={`py-2 px-12 rounded-full ${classNames} ${minWidth}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

/**
 * 직접등록건 취소 버튼
 */
const CancelDeliveryButton = ({ delivery }) => {
  const { ...actions } = useDeliveriesStore();
  const { ...loadingActions } = useLoadingStore();

  const handleClickCancelDelivery = async () => {
    if (window.confirm(`${delivery.bookId} 건을 취소하시겠습니까?`)) {
      try {
        loadingActions.startLoading();

        const data = {
          nonDoobal: true,
        };

        await actions.cancel({
          bookId: delivery.bookId,
          data,
        });

        window.alert("취소처리 되었습니다.");

        loadingActions.finishLoading();
        // TODO @AKI 취소 이후 목록 페이지로 이동처리
      } catch (e) {
        window.alert(`취소에 실패했습니다. ${e.message}`);
        loadingActions.finishLoading();
      }
    }
  };

  return (
    <ButtonSquare bgColor="bg-mint" onClick={handleClickCancelDelivery}>
      직접등록건 취소
    </ButtonSquare>
  );
};

/**
 * 배송/반송/분실 완료 취소 버튼
 */
const CancelDeliveryCompletedButton = ({ delivery, onAfterSubmit }) => {
  const { ...actions } = useDeliveriesStore();
  const { ...loadingActions } = useLoadingStore();

  const handleClickCancelDeliveryCompleted = async () => {
    if (
      window.confirm(
        `${delivery.bookId} ${delivery.statusString}를 취소하시겠습니까?`,
      )
    ) {
      try {
        loadingActions.startLoading();

        await actions.setPrevStatusBulk({
          bookIds: [delivery.bookId],
          status: delivery.statusNubmer,
        });

        window.alert(
          `${delivery.bookId} ${delivery.statusString}를 취소했습니다. 실제 배송목록에서 배송건을 확인할 수 있습니다.`,
        );

        onAfterSubmit();
      } catch (e) {
        window.alert(
          `${delivery.statusString} 취소에 실패했습니다. ${e.message}`,
        );
        loadingActions.finishLoading();
      }
    }
  };

  return (
    <ButtonSquare
      bgColor="bg-orange"
      onClick={handleClickCancelDeliveryCompleted}
    >
      {delivery.statusString} 취소
    </ButtonSquare>
  );
};

/**
 * 배송 지연 버튼
 */
const DeliveryDelayButton = ({ delivery, onAfterSubmit }) => {
  const { openModal } = useModalStore();

  const handleClickDelay = () => {
    openModal(
      <DeliveryDelayModal
        key="delivery-delay-modal"
        delivery={delivery}
        onAfterSubmit={onAfterSubmit}
      />,
    );
  };

  return (
    <ButtonRound
      type="ORANGE"
      minWidth="min-w-[80px]"
      onClick={handleClickDelay}
    >
      {delivery.recentDelayedDelivery
        ? formatDate(delivery.recentDelayedDelivery.createdAt, "HH:mm")
        : "배송지연"}
      {delivery.isrequiredDelayMessage && "(!)"}
    </ButtonRound>
  );
};

/**
 * 분실완료 버튼
 */
const DeliveryLostButton = ({ delivery, onAfterSubmit }) => {
  const { openModal } = useModalStore();

  const handleClickLost = () => {
    openModal(
      <DeliveryLostModal
        key="delivery-lost-modal"
        delivery={delivery}
        onAfterSubmit={onAfterSubmit}
      />,
    );
  };

  return (
    <button
      type="button"
      className="bg-orange text-white py-2 px-8 rounded-10 ml-5"
      onClick={handleClickLost}
    >
      분실완료
    </button>
  );
};

/**
 * 배송 연기 버튼
 */
const DeliveryPostponedButton = ({ delivery, onAfterSubmit }) => {
  const { ...actions } = useDeliveriesStore();
  const { ...loadingActions } = useLoadingStore();

  const handleClickPostponed = async () => {
    if (
      window.confirm(
        `${delivery.bookId} 건을 배송연기 상태로 변경하시겠습니까?`,
      )
    ) {
      try {
        loadingActions.startLoading();
        await actions.setPostponed({ bookId: delivery.bookId });

        onAfterSubmit();
      } catch (e) {
        window.alert(`배송연기 처리에 실패했습니다. ${e.message}`);
        loadingActions.finishLoading();
      }
    }
  };

  return (
    <ButtonSquare bgColor="bg-orange" onClick={handleClickPostponed}>
      연기등록
    </ButtonSquare>
  );
};

/**
 * 반송완료 버튼
 */
const DeliverySentBackButton = ({ delivery, onAfterSubmit }) => {
  const { openModal } = useModalStore();

  const handleClickSentBack = () => {
    openModal(
      <DeliverySentBackModal
        key="delivery-sentback-modal"
        delivery={delivery}
        onAfterSubmit={onAfterSubmit}
      />,
    );
  };

  return (
    <button
      type="button"
      className="bg-orange text-white py-2 px-8 rounded-10 ml-5"
      onClick={handleClickSentBack}
    >
      반송완료
    </button>
  );
};

/**
 * 실배송 버튼
 */
const DeliveryStartButton = ({ delivery, onAfterSubmit }) => {
  const { ...actions } = useDeliveriesStore();
  const { ...loadingActions } = useLoadingStore();

  const handleClickStart = async () => {
    if (
      window.confirm(
        `${delivery.bookId} 건을 배송출발 상태로 변경하시겠습니까?`,
      )
    ) {
      try {
        loadingActions.startLoading();
        await actions.setStart({ bookId: delivery.bookId });

        onAfterSubmit();
      } catch (e) {
        window.alert(`실배송 처리에 실패했습니다. ${e.message}`);
        loadingActions.finishLoading();
      }
    }
  };

  return (
    <ButtonSquare bgColor="bg-orange" onClick={handleClickStart}>
      실배송등록
    </ButtonSquare>
  );
};

/**
 * 주소 수정 버튼
 */
const UpdateAddressButton = ({
  delivery,
  type = "receiver",
  onAfterSubmit,
}) => {
  const { ...actions } = useDeliveriesStore();
  const { ...loadingActions } = useLoadingStore();

  const handleClickUpdateAddress = () => {};

  return (
    <ButtonSquare bgColor="bg-orange" onClick={handleClickUpdateAddress}>
      주소 수정
    </ButtonSquare>
  );
};

function Delivery() {
  const params = new URLSearchParams(window.location.search);
  const bookId = params.get("bookId");

  const { baseAddresses, baseAddressesAll } = useBaseAddressesStore().state;
  const { ...baseAddressesActions } = useBaseAddressesStore();

  const { delivery } = useDeliveriesStore().state;
  const { ...actions } = useDeliveriesStore();

  const { ...loadingActions } = useLoadingStore();

  useEffect(() => {
    if (bookId) {
      fetchById();
    }
  }, [bookId]);

  useEffect(() => {
    if (delivery?.bookId === bookId) {
      fetchBaseAddressById();
    }
  }, [delivery, bookId]);

  const fetchById = async () => {
    try {
      loadingActions.startLoading();
      await actions.fetchById(bookId);
    } catch (e) {
      window.alert(`배송 조회에 실패했습니다. ${e.message}`);
    }

    loadingActions.finishLoading();
  };

  const fetchBaseAddressById = async () => {
    try {
      await baseAddressesActions.fetchAllById({
        bookId: delivery.bookId,
        buildingDongNumber: delivery.typeNormal
          ? delivery.receiverBuildingDongNumber
          : delivery.senderBuildingDongNumber,
        addressRoad: delivery.typeNormal
          ? delivery.receiverAddressRoad
          : delivery.senderAddressRoad,
        address: delivery.typeNormal
          ? delivery.receiverAddress
          : delivery.senderAddress,
      });
    } catch (e) {
      window.alert(`주소 정보 조회에 실패했습니다. ${e.message}`);
    }
  };

  if (!delivery) {
    return <></>;
  }

  return (
    <div>
      {/** 주요 정보 */}
      <div className="bg-[#ffff00] p-10 grid gap-0">
        {(delivery.statusPickup || delivery.statusDelivery) && (
          <div className="flex justify-end items-center gap-5">
            {/** 편의점 바코드 */}
            {delivery.isConvenienceBarcode && (
              <button
                type="button"
                className="bg-mint text-white py-2 px-12 rounded-full min-w-[80px]"
              >
                GS25
              </button>
            )}

            {/** 재배차 처리 */}
            <button
              type="button"
              className="bg-white text-orange py-2 px-12 rounded-full min-w-[80px] flex justify-center items-center"
            >
              <img
                src={`/images/rearrange${
                  delivery.reallocatedRiderId ? "" : "-red"
                }.png`}
                alt=""
                className="w-24"
              />
              재배차
            </button>

            {/** 픽/반품 수거건 취소 */}
            {delivery.statusPickup && delivery.typePickupReturn && (
              <button
                type="button"
                className="bg-orange text-white py-2 px-12 rounded-full min-w-[80px]"
              >
                취소
              </button>
            )}
            {/** 배송 지연 처리 */}
            {delivery.statusDelivery && (
              <DeliveryDelayButton
                delivery={delivery}
                onAfterSubmit={fetchById}
              />
            )}
          </div>
        )}

        {delivery.orderIdFromCorp && (
          <InfoRow>
            <InfoLabel>사측번호</InfoLabel>
            <InfoValue>{delivery.orderIdFromCorp}</InfoValue>
          </InfoRow>
        )}

        <InfoRow>
          <InfoLabel>고객요청</InfoLabel>
          <InfoValue>{delivery.memo || "없음"}</InfoValue>
        </InfoRow>

        {delivery.isLimitedHours && (
          <InfoRow>
            <InfoLabel>확인사항</InfoLabel>
            <InfoValue>
              {formatDate(delivery.limitedDeliveryAt, "HH시 mm분")}까지 도착
              보장
            </InfoValue>
          </InfoRow>
        )}

        {baseAddresses?.length > 0 && (
          <InfoRow>
            <InfoLabel>주소정보</InfoLabel>
            <InfoValue>{baseAddresses[0].memo}</InfoValue>
          </InfoRow>
        )}

        {delivery.isHighPrice && (
          <InfoRow>
            <InfoLabel>{!delivery.isLimitedHours && "확인사항"}</InfoLabel>
            <InfoValue>고객연락 및 확인 필수</InfoValue>
          </InfoRow>
        )}

        <InfoRow>
          <InfoLabel>아파트동</InfoLabel>
          <InfoValue>
            {delivery.typeNormal
              ? delivery.receiverBuildingDongNumber || "없음"
              : delivery.senderBuildingDongNumber || "없음"}
            ({delivery.renderAddressApiType})
          </InfoValue>
        </InfoRow>
      </div>

      {/** 현관 비밀번호 */}
      {delivery.frontdoorPassword && (
        <div className="p-10">
          <button
            className="bg-orange text-white text-20 text-center rounded-4 w-full relative px-20 py-2"
            type="button"
          >
            <img
              src="/images/key.png"
              alt=""
              className="absolute w-32 h-32 left-5 top-[50%] mt-[-18px]"
            />
            {delivery.frontdoorPassword}
          </button>
        </div>
      )}

      {/** 출발지 정보 */}
      <div className="p-10 text-gray-8">
        <div className="flex justify-between items-center">
          <div className="flex justify-start items-center">
            <div className="text-20 text-black">출</div>
            {delivery.statusDelivery && delivery.typeNormal && (
              <div className="ml-5">
                {delivery.spotName}
                {delivery.spot?.name ? `(${delivery.senderName})` : ""}
              </div>
            )}
          </div>
          <div className="flex justify-end items-center">
            {delivery.statusPickup &&
              delivery.typePickupReturn &&
              delivery.senderMobile && (
                <button
                  type="button"
                  className="bg-white text-gray-a border-1 border-gray-d py-2 px-12 rounded-10 ml-5"
                >
                  문자
                </button>
              )}
            {delivery.senderMobile && (
              <button
                type="button"
                className="bg-white text-gray-a border-1 border-gray-d py-2 px-12 rounded-10 ml-5"
              >
                통화
              </button>
            )}
          </div>
        </div>
        {!delivery.statusDelivery && (
          <div className="mt-10 grid gap-5">
            <div className="text-18">
              {delivery.spotName}
              {delivery.spot?.name ? `(${delivery.senderName})` : ""}
            </div>
            <div className="text-18">
              {delivery.senderAddressRoadWithoutSigungu}{" "}
              {delivery.senderBuilding}
            </div>
            <div className="text-18">
              {delivery.deliveryPlace && delivery.typePickupReturn && (
                <span className="font-bold text-white bg-orange mr-5">
                  보관등록
                </span>
              )}
              {delivery.senderAddress || delivery.senderAddressRoad || ""}
              {delivery.senderAddressDetail
                ? `, ${delivery.senderAddressDetail}`
                : ""}
            </div>

            <div className="flex justify-start items-center gap-5">
              <button
                type="button"
                className="bg-mint text-white py-4 px-12 flex-1"
              >
                위치보기
              </button>
              {delivery.typePickupReturn && (
                <button
                  type="button"
                  className="bg-mint text-white py-4 px-12 flex-1"
                >
                  주소정보
                </button>
              )}

              {delivery.statusPickup && delivery.typeNormal && (
                <button
                  type="button"
                  className="bg-orange text-white py-4 px-12 flex-1"
                >
                  수거완료
                </button>
              )}
            </div>

            {delivery.statusPickup && delivery.typePickupReturn && (
              <div className="flex justify-start items-center gap-5">
                <button
                  type="button"
                  className="bg-orange text-white py-4 px-12 flex-1"
                >
                  사진선택
                </button>
                <button
                  type="button"
                  className="bg-orange text-white py-4 px-12 flex-1"
                >
                  수거촬영
                </button>
              </div>
            )}
          </div>
        )}
      </div>

      {/** divide */}
      <div className="h-1 bg-gray-d" />

      {/** 도착지 정보 */}
      <div className="p-10 text-gray-8">
        <div className="flex justify-between items-center">
          <div className="flex justify-start items-center">
            <div className="text-20 text-black">도</div>
            {delivery.isHighPrice && (
              <div className="bg-orange w-16 h-16 rounded-full ml-5" />
            )}
          </div>
          <div className="flex justify-end items-center">
            {delivery.statusDeliveryCompleted &&
              (delivery.receiverSmsMobile || delivery.receiverMobile) && (
                <button
                  type="button"
                  className={`${
                    delivery.sendedCompletedSms
                      ? "bg-white text-gray-a border-gray-d"
                      : "bg-orange text-white border-orange"
                  } border-1  py-2 px-12 rounded-10 ml-5`}
                >
                  위치
                </button>
              )}
            {(delivery.receiverSmsMobile || delivery.receiverMobile) && (
              <button
                type="button"
                className="bg-white text-gray-a border-1 border-gray-d py-2 px-12 rounded-10 ml-5"
              >
                문자
              </button>
            )}
            {delivery.receiverMobile && (
              <button
                type="button"
                className="bg-white text-gray-a border-1 border-gray-d py-2 px-12 rounded-10 ml-5"
              >
                전화1
              </button>
            )}
            {delivery.receiverMobile2 && (
              <button
                type="button"
                className="bg-white text-gray-a border-1 border-gray-d py-2 px-12 rounded-10 ml-5"
              >
                전화2
              </button>
            )}
          </div>
        </div>
        <div className="mt-10">
          <div className="flex justify-between items-center mb-5">
            <div className="text-18">{delivery.receiverName}</div>
            <div className="flex justify-end items-center">
              {delivery.statusDeliveryStart && delivery.typeNormal && (
                <DeliveryLostButton
                  delivery={delivery}
                  onAfterSubmit={fetchById}
                />
              )}
              {delivery.statusDeliveryStart && delivery.typeNormal && (
                <DeliverySentBackButton
                  delivery={delivery}
                  onAfterSubmit={fetchById}
                />
              )}
            </div>
          </div>
          <div className="mt-5 grid gap-5">
            {delivery.receiverPoint ? (
              <div className="text-18">
                {delivery.soojioneLimitedCustomized && (
                  <span className="font-bold text-white bg-orange mr-5">
                    주의{delivery.distanceRefineToLimitedRefined}
                  </span>
                )}
                {(delivery.spotKurlyDrink || delivery.mustSignature) && (
                  <span className="font-bold text-white bg-orange mr-5">
                    서명
                  </span>
                )}
                {delivery.receiverAddressRoadWithoutSigungu ||
                  delivery.receiverAddressWithoutSigungu ||
                  ""}{" "}
                {delivery.receiverBuilding}
                {delivery.receiverAddressRoadWithoutSigungu &&
                delivery.receiverAddressWithoutSigungu
                  ? `/${delivery.receiverAddressWithoutSigungu}`
                  : ""}
                {!delivery.receiverAddressRoadWithoutSigungu &&
                delivery.receiverDong
                  ? `/${delivery.receiverDong}`
                  : ""}
              </div>
            ) : (
              <div className="flex justify-start items-center gap-5 mt-10">
                <button
                  type="button"
                  className="bg-orange text-white py-4 px-12 flex-1"
                >
                  위치 등록 (필수)
                </button>
              </div>
            )}
            <div className="text-18">{delivery.displayAddress}</div>
            {delivery.customerAddressEngGpt && (
              <div className="text-black font-bold text-18">
                (GPT번역) {delivery.customerAddressEngGpt}
              </div>
            )}
            {delivery.customerAddressEng && (
              <div className="text-18">
                (번역주의) {delivery.customerAddressEng}
              </div>
            )}

            {/** 배송 대기 상태  */}
            {delivery.typeNormal && delivery.statusWaiting && (
              <ButtonsWrap>
                <DeliveryPostponedButton
                  delivery={delivery}
                  onAfterSubmit={fetchById}
                />
                <DeliveryStartButton
                  delivery={delivery}
                  onAfterSubmit={fetchById}
                />
              </ButtonsWrap>
            )}

            {/** 배송 완료후 사진 업로드 실패 */}
            <div className="text-red">
              사진 등록이 실패했습니다. 다시 등록해주십시오.
            </div>
            <div className="flex justify-start items-center gap-5">
              <button
                type="button"
                className="bg-orange text-white py-4 px-12 flex-1"
              >
                재촬영
              </button>
              <button
                type="button"
                className="bg-orange text-white py-4 px-12 flex-1"
              >
                사진선택
              </button>
            </div>

            {/** 배송 완료 처리 */}
            <div className="flex justify-start items-center gap-5">
              <button
                type="button"
                className="bg-orange text-white py-4 px-12 flex-1"
              >
                수취확인
              </button>
            </div>

            <div className="flex justify-start items-center gap-5">
              <button
                type="button"
                className="bg-orange text-white py-4 px-12 flex-1"
              >
                사진촬영
              </button>
            </div>
            <div className="flex justify-start items-center gap-5">
              <button
                type="button"
                className="bg-orange text-white py-4 px-12 flex-1"
              >
                사진선택
              </button>
              <button
                type="button"
                className="bg-orange text-white py-4 px-12 flex-1"
              >
                수취확인
              </button>
            </div>

            {/** 위치 / 주소정보 */}
            <div className="flex justify-start items-center gap-5">
              <button
                type="button"
                className="bg-mint text-white py-4 px-12 flex-1"
              >
                위치보기
              </button>
              <button
                type="button"
                className="bg-mint text-white py-4 px-12 flex-1"
              >
                주소정보
              </button>
              <button
                type="button"
                className="bg-mint text-white py-4 px-12 flex-1"
              >
                경로찾기
              </button>
            </div>

            {/** 주소 수정 */}
            {delivery.statusDelivery && (
              <div className="flex justify-start items-center gap-5">
                <UpdateAddressButton
                  delivery={delivery}
                  type="receiver"
                  onAfterSubmit={fetchById}
                />
              </div>
            )}

            {/** 직접등록건 취소 */}
            {delivery.statusDeliveryStart && delivery.nonDoobalType && (
              <div className="flex justify-start items-center gap-5">
                <CancelDeliveryButton delivery={delivery} />
              </div>
            )}

            {/** 배송불가 취소처리 */}
            <div className="flex justify-start items-center gap-5">
              <button
                type="button"
                className="bg-mint text-white py-4 px-12 flex-1"
              >
                배송불가지역 배차 취소
              </button>
            </div>

            {/** 배송 완료 상태 */}
            {delivery.statusDeliveryCompleted && (
              <>
                <ButtonsWrap>
                  <button
                    type="button"
                    className="bg-mint text-white py-4 px-12 flex-[2]"
                  >
                    주소위치/완료위치
                  </button>
                  <button
                    type="button"
                    className="bg-mint text-white py-4 px-12 flex-1"
                  >
                    주소정보
                  </button>
                </ButtonsWrap>
                <ButtonsWrap>
                  <CancelDeliveryCompletedButton
                    delivery={delivery}
                    onAfterSubmit={fetchById}
                  />
                  {/* <button
                  type="button"
                  className="bg-orange text-white py-4 px-12 flex-1"
                >
                  파일재업로드
                </button> */}
                  <button
                    type="button"
                    className="bg-orange text-white py-4 px-12 flex-1"
                  >
                    서명/사진 보기
                  </button>
                </ButtonsWrap>
              </>
            )}

            {/** 반송 완료 상태 */}
            {delivery.statusDeliverySentBack && (
              <ButtonsWrap>
                <CancelDeliveryCompletedButton
                  delivery={delivery}
                  onAfterSubmit={fetchById}
                />
                <button
                  type="button"
                  className="bg-orange text-white py-4 px-12 flex-1"
                >
                  주소위치/반송위치
                </button>
              </ButtonsWrap>
            )}

            {/** 분실 완료 상태 */}
            {delivery.statusDeliveryLost && (
              <ButtonsWrap>
                <CancelDeliveryCompletedButton
                  delivery={delivery}
                  onAfterSubmit={fetchById}
                />
                <button
                  type="button"
                  className="bg-orange text-white py-4 px-12 flex-1"
                >
                  주소위치/분실위치
                </button>
              </ButtonsWrap>
            )}
          </div>
        </div>
      </div>

      {/** divide */}
      <div className="h-1 bg-gray-d" />

      {/** 송하인 정보 */}
      <div className="p-10 text-gray-8">
        <div className="flex justify-between items-center">
          <div className="flex justify-start items-center">
            <div className="text-20 text-black">송하인</div>
          </div>
        </div>
        <div className="mt-10">
          <div className="flex justify-between items-center mb-5">
            <div className="text-18">{delivery.senderName}</div>
            <div className="flex justify-end items-center">
              {delivery.senderMobile && (
                <button
                  type="button"
                  className="bg-white text-gray-a border-1 border-gray-d py-2 px-12 rounded-10 ml-5"
                >
                  통화
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Delivery;
